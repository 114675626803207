//@author: devin
import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { S25Dateformat } from "../modules/s25-dateformat/s25.dateformat.service";
import { Cache } from "../decorators/cache.decorator";
import { LoginService } from "./login.service";
import { Timeout } from "../decorators/timeout.decorator";
import { EventStateConst } from "./event.state.change.service";
import { Event } from "../pojo/Event";

declare global {
    interface Window {
        angBridge: any;
    }
}

export class UserprefService {
    public static S25TimeZoneToReal: any = {
        "Europe/Canary": "Atlantic/Canary",
        "Europe/Saint_Petersburg": "Europe/Simferopol",
        "Asia/Calcutta": "Asia/Novosibirsk",
        "Asia/Katmandu": "Asia/Novosibirsk",
        "Asia/Dacca": "Asia/Novosibirsk",
        "Asia/Alma-Ata": "Asia/Novosibirsk",
        "Asia/Ulan_Bator": "Asia/Irkutsk",
        "America/Guam": "Pacific/Guam",
        "Universal Coordinated Time 2 [UCT2]": "Atlantic/South_Georgia",
        "America/Buenos_Aires": "America/Argentina/Buenos_Aires",
        "America/Indianapolis": "America/Indiana/Indianapolis",
        "Universal Coordinated Time 7 [UCT7]": "America/Denver",
        "Universal Coordinated Time 8 [UCT8]": "America/Los_Angeles",
        "Universal Coordinated Time 9 [UCT9]": "America/Anchorage",
        "America/Alaska": "America/Anchorage",
    };

    @Cache({ targetName: "UserprefService" })
    public static getUserPrefs() {
        return DataAccess.get(DataAccess.injectCaller("/userprefs.json", "UserprefDao.getUserPrefs")).then(
            function (data) {
                return data.userprefs;
            },
        );
    }

    @Cache({ targetName: "UserprefService" })
    public static getSessionId(): Promise<string> {
        return DataAccess.get(DataAccess.injectCaller("/session/id.json", "UserprefDao.getSessionId")).then(
            (resp: any) => {
                return resp && resp.session_id;
            },
        );
    }

    @Cache({ targetName: "UserprefService" })
    public static getUserGroupRoseSecurity() {
        return DataAccess.get(
            DataAccess.injectCaller("/usergroup/wizardsecurity.json", "UserprefDao.getUserGroupRoseSecurity"),
        ).then(function (config) {
            //make sure org_roles_allowed is a string (it could be a number)
            if (config?.root?.org_roles_allowed) {
                config.root.org_roles_allowed = "" + config.root.org_roles_allowed;
            }
            if (config?.root?.organizations_allowed) {
                config.root.organizations_allowed = "" + config.root.organizations_allowed;
            }
            return config?.root;
        });
    }

    public static getAllowedStates(): Promise<Event.State.Id[]> {
        return UserprefService.getUserGroupRoseSecurity().then(function (sec) {
            let allowedEventStates =
                sec && S25Util.isDefined(sec.event_states_allowed) && "" + sec.event_states_allowed;
            return (
                (allowedEventStates && allowedEventStates.trim().split(" ").map(S25Util.toInt)) ||
                EventStateConst.allEventStates
            );
        });
    }

    @Cache({ targetName: "UserprefService" })
    public static getGroupId() {
        //security group id (r25members group id)
        return UserprefService.getUserPrefs().then(function (data) {
            return parseInt(data.group_id);
        });
    }

    @Cache({ targetName: "UserprefService" })
    public static getEarliestEventCreationDate() {
        var returnDate = S25Util.date.parse("1/1/2001");
        return UserprefService.getUserGroupRoseSecurity().then(function (config) {
            var eventCreateMinDate = S25Util.propertyGetVal(config, "event_create_min_date"); //value includes min buffer/earliest logic...
            if (eventCreateMinDate) {
                try {
                    //try to parse as date, else fall back to default returnDate...
                    eventCreateMinDate = S25Util.date.parse(eventCreateMinDate);
                    if (eventCreateMinDate > returnDate) {
                        returnDate = eventCreateMinDate;
                    }
                } catch (e) {}
            }

            returnDate.setHours(1, 0, 0, 0);
            return returnDate;
        });
    }

    @Cache({ targetName: "UserprefService" })
    public static getLatestEventCreationDate() {
        var returnDate = new Date(new Date().getFullYear() + 10, 11, 31); //12/31/{10 years in the future}
        return UserprefService.getUserGroupRoseSecurity().then(function (config) {
            var eventCreateMaxDate = S25Util.propertyGetVal(config, "event_create_max_date"); //value includes max buffer/latest logic...
            if (eventCreateMaxDate) {
                try {
                    //try to parse as date, else fall back to default returnDate...
                    eventCreateMaxDate = S25Util.date.parse(eventCreateMaxDate);
                    if (eventCreateMaxDate < returnDate) {
                        returnDate = eventCreateMaxDate;
                    }
                } catch (e) {}
            }

            returnDate.setHours(23, 59, 59, 999);
            return returnDate;
        });
    }

    @Cache({ targetName: "UserprefService" })
    // compose dateFormat out of user's datetimepref and return promise
    public static getS25Dateformat() {
        // return a new derived promise with special resolver
        // https://docs.angularjs.org/api/ng/service/$q
        // then() method of a promise returns a new derived promise
        // function inside then() is a resolver for the promise returned by then()
        // thus return value of the resolver function is passed to the caller
        return UserprefService.getUserPrefs().then(function (data) {
            // special resolver - return S25Dateformat composed out of data (datetimepref)
            return S25Dateformat.getDateformat(data);
        });
    }

    public static getIs24HourTime() {
        return UserprefService.getUserPrefs().then(function (data) {
            return (data && data.timedisplay == 24) || false;
        });
    }

    @Cache({ targetName: "UserprefService" })
    public static getS25Timeformat() {
        return UserprefService.getUserPrefs().then(function (data) {
            // special resolver - return S25Dateformat composed out of data (datetimepref)
            return S25Dateformat.getTimeformat(data);
        });
    }

    public static getWeekstart(): Promise<0 | 1 | 2 | 3 | 4 | 5 | 6> {
        return UserprefService.getUserPrefs().then(function (data) {
            return data.weekstart == 7 ? 0 : data.weekstart; //use a 0-based index
        });
    }

    @Cache({ targetName: "UserprefService" })
    public static getS25TimeformatShort() {
        return UserprefService.getUserPrefs().then(function (data) {
            // no AM/PM part
            return S25Dateformat.getTimeformatShort(data);
        });
    }

    @Cache({ targetName: "UserprefService" })
    public static getS25TimeformatAMPM() {
        return UserprefService.getUserPrefs().then(function (data) {
            return S25Dateformat.getTimeformatAMPM();
        });
    }

    @Cache({ targetName: "UserprefService" })
    public static getS25DateTimeformat() {
        return UserprefService.getUserPrefs().then(function (data) {
            return S25Dateformat.getDateTimeFormat(data);
        });
    }

    @Cache({ targetName: "UserprefService" })
    public static getDisableTooltip() {
        return UserprefService.getUserPrefs().then(function (data) {
            return !!data.disableTooltip;
        });
    }

    @Timeout
    @Cache({ targetName: "UserprefService" })
    public static getLoggedIn() {
        return LoginService.getLoggedIn();
    }

    @Cache({ targetName: "UserprefService" })
    public static getUserTypeWeb() {
        return UserprefService.getUserPrefs().then(function (data) {
            return data.userType === "web";
        });
    }

    @Cache({ targetName: "UserprefService" })
    public static getContactId() {
        return UserprefService.getUserPrefs().then(function (data) {
            return parseInt(data.contactId);
        });
    }

    @Cache({ targetName: "UserprefService" })
    public static getAuth() {
        return S25Util.all({
            loggedIn: UserprefService.getLoggedIn(),
            userTypeWeb: UserprefService.getUserTypeWeb(),
            contactId: UserprefService.getContactId(),
        }).then(function (resolve: any) {
            return {
                loggedIn: resolve.loggedIn,
                userTypeWeb: resolve.userTypeWeb,
                contactId: resolve.contactId,
            };
        });
    }

    @Cache({ targetName: "UserprefService" })
    public static getTZName() {
        return UserprefService.getUserPrefs().then(function (data) {
            return UserprefService.S25TimeZoneToReal[data.timezoneName] || data.timezoneName;
        });
    }
}
