//@author: devin
import { S25Util } from "../util/s25-util";

export class UserprefDateBarrierService {
    public static isValid(date: Date, isActive?: boolean, minDate?: Date, maxDate?: Date): boolean {
        if (!isActive || (!minDate && !maxDate)) {
            return true;
        }

        if (!maxDate) {
            return date >= minDate;
        }

        if (!minDate) {
            return date <= maxDate;
        }

        return date >= minDate && date <= maxDate;
    }

    public static getValid(date: Date, isActive?: boolean, minDate?: Date, maxDate?: Date): Date {
        if (UserprefDateBarrierService.isValid(date, isActive, minDate, maxDate)) {
            return date;
        }
        return (date < minDate && minDate) || (date > maxDate && maxDate);
    }

    public static init(modelBean: any, isActive?: boolean, minDate?: Date, maxDate?: Date): void {
        var self = this;
        if (!isActive) {
            return;
        }

        modelBean.minDate = self._parseDate(minDate);
        modelBean.maxDate = self._parseDate(maxDate);
    }

    // there might be only one minDate || maxDate, so leave other as undefined
    public static _parseDate(date: any) {
        if (S25Util.isUndefined(date)) {
            return undefined;
        }
        return S25Util.date.parse(date);
    }

    public static create(isActive?: boolean): any {
        return {
            isActive: isActive,
            // set by init()
            minDate: undefined,
            maxDate: undefined,
            init: function (userprefs: any) {
                if (!userprefs || !userprefs.security) {
                    return;
                }

                UserprefDateBarrierService.init(
                    this,
                    this.isActive,
                    userprefs.security.eventCreateMinDate,
                    userprefs.security.eventCreateMaxDate,
                );
            },
            isValid: function (date: Date) {
                return UserprefDateBarrierService.isValid(date, this.isActive, this.minDate, this.maxDate);
            },
            getValid: function (date: Date) {
                return UserprefDateBarrierService.getValid(date, this.isActive, this.minDate, this.maxDate);
            },
        };
    }
}
