//@author: devin
import { UserprefService } from "./userpref.service";

export class UserprefStaticCachedService {
    public static init() {
        return UserprefStaticCachedService._init();
    }

    public static getS25Timeformat() {
        return UserprefStaticCachedService._cachedPreferences.s25Timeformat;
    }

    // try keep this hack cache minimal;
    // 20150626 - only S25TimeFormat in the cache so far
    public static _cachedPreferences: any = {
        s25Timeformat: undefined,
    };

    // initialize our cache at application start-up
    public static _init() {
        return UserprefService.getS25Timeformat().then(function (s25Timeformat) {
            UserprefStaticCachedService._cachedPreferences.s25Timeformat = s25Timeformat;
        });
    }
}
