import { S25Util } from "../../util/s25-util";
import { PreferenceService } from "../preference.service";
import { UserprefService } from "../userpref.service";

const TASK_SELECTION_STATIC_MAP: any = {
    Overdue: {
        taskStateArray: [1],
        taskTypeArray: [2, 3, 4, 5],
        hasDates: true,
        endDtOffset: 0,
    },
    Outstanding: {
        onlyOutstanding: "T",
    },
    Flagged: {
        onlyUnread: "T",
    },
    Completed: {
        taskStateArray: [2, 3],
        taskTypeArray: [2, 3, 4, 5],
        hasDates: true,
        endDtOffset: 0,
    },
    Assigned_All: {
        taskTypeArray: [1, 2, 3, 4, 5],
        hasAssignedBy: true,
        hasDates: true,
    },
    Assigned_Completed: {
        taskStateArray: [2, 3],
        taskTypeArray: [1, 2, 3, 4, 5],
        hasAssignedBy: true,
        hasDates: true,
    },
    Assigned_Denied: {
        taskStateArray: [3],
        taskTypeArray: [1, 2, 3, 4, 5],
        hasAssignedBy: true,
        hasDates: true,
    },
    Assigned_Outstanding: {
        taskStateArray: [1],
        taskTypeArray: [1, 2, 3, 4, 5],
        hasAssignedBy: true,
        hasDates: true,
    },
    Today_Agenda: {
        hasDates: true,
    },
};

export class TaskSelectionMap {
    public static compute(selection: string) {
        var today = S25Util.date.getDate(new Date());
        var staticParams = S25Util.deepCopy(TASK_SELECTION_STATIC_MAP[selection]) || {};
        return S25Util.all({
            currentContactId: UserprefService.getContactId(),
            prefs: PreferenceService.getPreferences(["TasksDueStartDtRange", "TasksDueEndDtRange"]),
        }).then(function (resp) {
            var startDtOffsetPref =
                parseInt(
                    S25Util.coalesce(resp.prefs.TasksDueStartDtRange && resp.prefs.TasksDueStartDtRange.value, 30),
                ) * -1;
            var endDtOffsetPref = parseInt(
                S25Util.coalesce(resp.prefs.TasksDueEndDtRange && resp.prefs.TasksDueEndDtRange.value, 30),
            );
            return S25Util.extend(staticParams, {
                currentContactId: resp.currentContactId,
                dueStartDt:
                    staticParams.hasDates &&
                    S25Util.date.toS25ISODateTimeStr(
                        S25Util.date.addSeconds(
                            S25Util.date.addDays(
                                today,
                                S25Util.coalesce(staticParams.startDtOffset, startDtOffsetPref),
                            ),
                            1,
                        ),
                        true,
                    ),
                dueEndDt:
                    staticParams.hasDates &&
                    S25Util.date.toS25ISODateStrEndOfDay(
                        S25Util.date.addDays(today, S25Util.coalesce(staticParams.endDtOffset, endDtOffsetPref)),
                    ),
                assignedBy: staticParams.hasAssignedBy && resp.currentContactId,
                queryId:
                    selection &&
                    (parseInt(selection) ||
                        (selection.split && selection.split("=").length > 1 && parseInt(selection.split("=")[1]))), //selection is id or is query id (&queryid=123, extract id)
            });
        });
    }
}
