import { Timeout } from "../../decorators/timeout.decorator";
import { Cache } from "../../decorators/cache.decorator";
import { PreferenceService } from "../preference.service";
import { Task } from "../../pojo/Task";
import WorkflowTypes = Task.WorkflowTypes;
import { S25Const } from "../../util/s25-const";
import Ids = Task.Ids;
import { Preference } from "../../pojo/PeferenceI";
import { S25Util } from "../../util/s25-util";
import { NotificationService } from "../notification.service";
import { TaskNormalizeUtil } from "./task.normalize.util";

export class TaskTiersService {
    /**
     * Central place to check for chained workflow status.
     */
    @Timeout
    public static async isWorkflowChained() {
        const workflowPref = await PreferenceService.getPreferences(["workflowChained"], "S");
        return workflowPref.workflowChained?.value === "T";
    }

    /*
  Tiers are:
  Event Type and Org (NP)
  Location AP -> NP
  Resource AP -> NP
  Requirement (NP)
   */
    /**
     * @param tasks
     * Given approvals on an event return the sorted tasks sorted into tiers and an overall state
     */
    public static sortTiers(tasks: Task.Object[]) {
        //Start with tiers as complete so empty tiers are don't end up as blocking anyway
        let tiers: TaskTier[] = [
            { isComplete: true, tierNum: 0, name: "ToDos and FYIs", tasks: [] },
            { isComplete: true, tierNum: 1, name: "Event Type and Organization Approvals", tasks: [] },
            { isComplete: true, tierNum: 2, name: "Location Assignments and Approvals", tasks: [] },
            { isComplete: true, tierNum: 3, name: "Resource Assignments and Approvals", tasks: [] },
            { isComplete: true, tierNum: 4, name: "Requirement Approvals", tasks: [] },
        ];

        tasks.forEach((task) => {
            const tierNum = TaskTiersService.getTierNumber(task);
            if (tierNum > -1) tiers[tierNum].tasks.push(task);

            if (task.isBlocked || task.overallState === Task.States.InProgress) {
                tiers[tierNum].isComplete = false;
            }
        });
        return tiers;
    }

    /*
    Returns the tier that this task lives in
    0 - outside of tiers, todo or fyi tasks
    1 - NP Approval on Event Type or organization
    2 - Location AP assignment or Location NP approval
    3 - Resource Ap assignment or Resource NP approval
    4 - Requirement Approval NP
    -1 - unknown task type
    Potential Gotcha - NP has both Approval AND FYI on the same task
     */
    public static getTierNumber(task: Task.Object) {
        if (task.taskType === WorkflowTypes.todo || task.taskTypeId === Ids.FYI) {
            return 0; //Exist Outside of Tiers
        } else if ([2, 7].includes(task.object?.itemTypeId)) {
            //org and event type
            return 1;
        } else if (task.object?.itemTypeId === S25Const.itemName2Id["location"]) {
            return 2;
        } else if (task.object?.itemTypeId === S25Const.itemName2Id["resource"]) {
            return 3;
        } else if (task.object?.itemTypeId === S25Const.itemName2Id["requirement"]) {
            return 4;
        } else {
            console.error("unable to sort the task", task);
            return -1;
        }
    }

    /*Get the currently open tier if all tiers complete return undefined
     */
    public static getOpenTier(tasks: Task.Object[]) {
        const tiers = TaskTiersService.sortTiers(tasks);
        //Omit actions outside of the tier system
        return tiers.slice(1).find((tier) => !tier.isComplete);
    }

    /**
     * Take in any sort of approval data (from events, tasks, or normalized)
     * @param newApprovals
     * @param preApprovals
     */
    public static notifyOnNewTier(newApprovals: any[], preApprovals: any[]) {
        const startingTier = TaskTiersService.getOpenTier(
            S25Util.array.forceArray(preApprovals).map(TaskNormalizeUtil.normalizeTaskData),
        );

        const endingTier = TaskTiersService.getOpenTier(
            S25Util.array.forceArray(newApprovals).map(TaskNormalizeUtil.normalizeTaskData),
        );

        if (startingTier?.tierNum < endingTier?.tierNum || (startingTier && !endingTier)) {
            let newTierMsg = !!endingTier ? `${endingTier.name} available for action.` : `Tiers are complete.`;
            let msg = `${startingTier.name} is complete. ${newTierMsg}`;
            NotificationService.post(msg);
        }
    }
}

export interface TaskTier {
    isComplete: boolean;
    tierNum: number;
    name: string;
    tasks: Task.Object[];
}
