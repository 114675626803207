import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { Timeout } from "../decorators/timeout.decorator";
import { S25WsNode } from "../pojo/S25WsNode";

export class UserService {
    @Timeout
    private static putUser(username: string, payload: any) {
        return UserService.getUser(username).then((user) => {
            let url = "/r25user.json?r25_username=" + username;
            S25Util.coalesceDeep(payload, user);
            return DataAccess.put(
                DataAccess.injectCaller(url, "UserService.putUser"),
                S25Util.getPayload("r25users", "r25_user", "username", "mod", user, payload),
            );
        });
    }

    @Timeout
    public static deleteUser(username: string) {
        let url = "/r25user.json?r25_username=" + username;
        return DataAccess.delete(DataAccess.injectCaller(url, "UserService.deleteUser"));
    }

    @Timeout
    public static postUser(username: string, payload: any) {
        return UserService.getUser(username)
            .then((resp) => resp)
            .catch(() => {
                // return username already in use or catch error when no user returned to post new user
                let data = {
                    r25users: {
                        r25_user: payload,
                    },
                };
                return DataAccess.post(DataAccess.injectCaller("/r25users.json", "UserService.postUser"), data);
            });
    }

    @Timeout
    public static async putUsername(contactId: number, username: string) {
        const payload = {
            new_username: username,
        };

        return DataAccess.put(
            DataAccess.injectCaller(`/micro/contact/${contactId}/username.json`, "UserService.putUsername"),
            payload,
        );
    }

    @Timeout
    private static getUser(username: string) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/r25user.json?r25_username=" + encodeURIComponent(username),
                "UserService.getUser",
            ),
        ).then((resp) => {
            return resp && resp.r25users && resp.r25users.r25_user && resp.r25users.r25_user[0];
        });
    }

    //One of those del/new services
    public static setSecurityGroup(username: string, groupId: number, groupName?: string) {
        return UserService.getUser(username).then((user) => {
            user.security_group = S25Util.array.forceArray(user.security_group);
            user.security_group[0].status = "del";
            user.security_group.push({
                status: "new",
                security_group_id: groupId,
                security_group_name: groupName,
            });
            delete user.r25_password;
            let payload = {
                r25users: {
                    r25_user: user,
                },
            };

            return DataAccess.put(
                DataAccess.injectCaller(
                    "/r25user.json?r25_username=" + encodeURIComponent(username),
                    "UserService.putUser",
                ),
                payload,
            );
        });
    }

    public static setActiveState(username: string, state: boolean) {
        let payload = {
            active: state ? "1" : "0",
        };
        return UserService.putUser(username, payload);
    }

    public static getUserSessions(usernameToFilter?: string): Promise<UserSession[]> {
        return DataAccess.get(DataAccess.injectCaller("/user_sessions.json", "UserService.getUserSessions")).then(
            (resp: any) => {
                if (usernameToFilter !== undefined) {
                    return (
                        resp?.sessions?.session.filter(
                            (session: UserSession) => session.user_name !== usernameToFilter,
                        ) || []
                    );
                }
                return resp?.sessions?.session || [];
            },
        );
    }
}

export type UserSession = {
    computer: string;
    contact_id: string | number;
    contact_name: string;
    email: string;
    logon_date: string;
    session_id: string | number;
    status: S25WsNode["status"];
    user_name: string;
};
