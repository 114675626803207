import { CalendarService } from "../../modules/home/calendar/calendar.service";
import { jSith } from "../../util/jquery-replacement";
import { S25Util } from "../../util/s25-util";
import { EventService } from "../event.service";
import { TaskSelectionMap } from "./task.selection.map";
import { TaskService } from "./task.service";

export class TaskCalendarService {
    //return date value (as ISO string) for passed-in date property (with fallback)
    public static taskCalendarDayDateString(datePropertyValue: any, taskObject: any) {
        //use dateProperty (can be respond_by or first_date) but fall back to respond_by since it is on each task...
        return S25Util.date.toS25ISODateTimeStr(
            S25Util.date.getDate(
                taskObject[datePropertyValue] || taskObject.respond_by || taskObject.creation_date || new Date(),
            ),
        );
    }

    //form calendar model from tasks data for use in task agenda and task master search calendar
    public static getCalendar(params: any) {
        var dateProperty = params.modelBean.optBean.datePropertyValue;
        return TaskSelectionMap.compute(params.modelBean.searchQuery).then(function (taskParamsResp) {
            //form task request params
            var taskParams = {
                taskBean: S25Util.extend(taskParamsResp, {
                    dueStartDt: S25Util.date.toS25ISODateTimeStr(params.modelBean.optBean.date),
                    dueEndDt: S25Util.date.toS25ISODateTimeStr(params.modelBean.optBean.endDate),
                }),
            };
            //make tasks api request
            return TaskService.getTasks(taskParams).then(function (tasks) {
                //form calendar data model from returned tasks
                var rsrvData: any = {};
                var taskArr = (tasks && tasks.tasks && tasks.tasks.task) || [];
                var eventIdArray: number[] = [];
                taskArr.map(function (obj: any) {
                    if (obj.event_id) {
                        eventIdArray.push(obj.event_id);
                    } else if (parseInt(obj.task_type) === 5) {
                        //to-do but NOT vcal to-do...
                        var dayDate = TaskCalendarService.taskCalendarDayDateString(dateProperty, obj);
                        rsrvData[dayDate] = rsrvData[dayDate] || [];
                        rsrvData[dayDate].push({
                            itemId: parseInt(obj.task_id),
                            itemName: "To Do",
                            itemTypeId: -1,
                            subject: [
                                {
                                    itemId: parseInt(obj.task_id),
                                    itemTypeId: 10,
                                    taskType: parseInt(obj.task_type),
                                    itemStateId: parseInt(obj.task_state),
                                    itemName: obj.task_name,
                                    comment: obj.comments,
                                    eventId: parseInt(obj.event_id),
                                    assignedToId: parseInt(obj.assigned_to_id),
                                    isTodo: parseInt(obj.task_type) === 5 || parseInt(obj.task_type) === 6,
                                },
                            ],
                        });
                    }
                });
                var included: any = ["reservations"];
                var eventPromise = eventIdArray ? EventService.getEventsExtended(eventIdArray, included) : jSith.when();

                return eventPromise.then(function (events: any) {
                    if (events && tasks && tasks.tasks && tasks.tasks.task) {
                        for (var i = 0; i < tasks.tasks.task.length; i++) {
                            //date in cal is respond by date
                            var dayDate = TaskCalendarService.taskCalendarDayDateString(
                                dateProperty,
                                tasks.tasks.task[i],
                            );
                            rsrvData[dayDate] = rsrvData[dayDate] || [];
                            for (var j = 0; j < events.length; j++) {
                                //find events related to this task
                                if (parseInt(events[j].event_id) === parseInt(tasks.tasks.task[i].event_id)) {
                                    //is true, they are related
                                    var item: any = {
                                        //a main item for calendar
                                        itemId: parseInt(events[j].event_id),
                                        itemName: events[j].event_name,
                                        itemTypeId: 1,
                                        subject: [],
                                    };

                                    if (
                                        params.modelBean.optBean.datePropertyValue === "first_date" &&
                                        events[j].profile
                                    ) {
                                        //params.modelBean.optBean.hasAddtlTime = true;
                                        item.rsrv_start_dt =
                                            S25Util.date.dropTZString(events[j].profile.init_start_dt) ||
                                            S25Util.date.dropTZString(events[j].profile[0].init_start_d);
                                        item.rsrv_end_dt =
                                            S25Util.date.dropTZString(events[j].profile.init_end_dt) ||
                                            S25Util.date.dropTZString(events[j].profile[0].init_end_dt);

                                        //get additional times
                                        if (params.modelBean.optBean.includeAddtl) {
                                            let minutes = {
                                                setupMinutes: S25Util.ISODurationToMinutes(
                                                    S25Util.propertyGetVal(events[j].profile, "setup_tm"),
                                                ),
                                                preMinutes: S25Util.ISODurationToMinutes(
                                                    S25Util.propertyGetVal(events[j].profile, "pre_event"),
                                                ),
                                                postMinutes: S25Util.ISODurationToMinutes(
                                                    S25Util.propertyGetVal(events[j].profile, "post_event"),
                                                ),
                                                takedownMinutes: S25Util.ISODurationToMinutes(
                                                    S25Util.propertyGetVal(events[j].profile, "tdown_tm"),
                                                ),
                                            };

                                            let addtl_start_dt = S25Util.date.addMinutes(
                                                item.rsrv_start_dt,
                                                -1 * (minutes.setupMinutes + minutes.preMinutes),
                                            );
                                            let addtl_end_dt = S25Util.date.addMinutes(
                                                item.rsrv_end_dt,
                                                minutes.postMinutes + minutes.takedownMinutes,
                                            );

                                            !S25Util.date.equal(item.rsrv_start_dt, addtl_start_dt)
                                                ? (item.addtl_start_dt = addtl_start_dt)
                                                : "";
                                            !S25Util.date.equal(item.rsrv_end_dt, addtl_end_dt)
                                                ? (item.addtl_end_dt = addtl_end_dt)
                                                : "";
                                        }
                                    }

                                    //get reservation_id in order to display time in popup
                                    if (events[j].profile) {
                                        item.itemId2 = S25Util.propertyGetVal(events[j].profile, "reservation_id");
                                    }

                                    var doesAlreadyExist = false;
                                    for (var k = 0; k < rsrvData[dayDate].length; k++) {
                                        //see if the event id already exists on this date in the calendar
                                        if (parseInt(rsrvData[dayDate][k].itemId) === parseInt(item.itemId)) {
                                            doesAlreadyExist = true;
                                            item = rsrvData[dayDate][k]; //if event already exists in calendar, get its item info so we can add the task to its subject list
                                            break;
                                        }
                                    }
                                    if (!doesAlreadyExist) {
                                        //if event does not exist, push it
                                        rsrvData[dayDate].push(item);
                                        //// start occ info ANG-1641
                                        if (
                                            params.modelBean.optBean.datePropertyValue === "first_date" &&
                                            events[j].profile
                                        ) {
                                            let profile: any = S25Util.array.forceArray(events[j].profile);
                                            for (let p = 0; p < profile.length; p++) {
                                                if (profile[p].reservation) {
                                                    let reservation: any = S25Util.array.forceArray(
                                                        profile[p].reservation,
                                                    );
                                                    if (reservation.length > 1) {
                                                        for (let r = 1; r < reservation.length; r++) {
                                                            // first date already exit. so start from 2nd date on
                                                            let occDate = S25Util.date.toS25ISODateTimeStr(
                                                                S25Util.date.getDate(reservation[r].event_start_dt),
                                                            );
                                                            rsrvData[occDate] = rsrvData[occDate] || [];
                                                            rsrvData[occDate].push(item);
                                                        }
                                                    }
                                                }
                                            }
                                        } //end occ info
                                    }
                                    item.subject.push({
                                        //push the task into the event's subject list
                                        //itemId: parseInt(tasks.tasks.task[i].task_id),  //ANG-2588  StateService.goToItem itemId is objectId
                                        //itemTypeId: 10,
                                        itemId: tasks.tasks.task[i].object_id, //ANG-2588  StateService.goToItem itemId is objectId
                                        itemTypeId:
                                            tasks.tasks.task[i].object_type === 2 ||
                                            tasks.tasks.task[i].object_type === 4 ||
                                            tasks.tasks.task[i].object_type === 6
                                                ? tasks.tasks.task[i].object_type
                                                : 10,
                                        taskId: parseInt(tasks.tasks.task[i].task_id),
                                        taskType: parseInt(tasks.tasks.task[i].task_type),
                                        itemStateId: parseInt(tasks.tasks.task[i].task_state),
                                        itemName: tasks.tasks.task[i].task_name,
                                        comment: tasks.tasks.task[i].comments,
                                        eventId: parseInt(tasks.tasks.task[i].event_id),
                                        assignedToId: parseInt(tasks.tasks.task[i].assigned_to_id),
                                        isTodo:
                                            parseInt(tasks.tasks.task[i].task_type) === 5 ||
                                            parseInt(tasks.tasks.task[i].task_type) === 6,
                                        todoType: parseInt(tasks.tasks.task[i].task_type) === 6 ? 100 : undefined, //100 indicates its a vCal to-do...
                                    });
                                }
                            }
                        } //end for tasks.task.....
                    }
                    return CalendarService.generateCalendar(
                        params.modelBean.optBean.date,
                        params.modelBean.optBean.endDate,
                        params.weekstart,
                        params.comptype,
                        rsrvData,
                    );
                });
            });
        });
    }
}
