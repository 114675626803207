import { DataAccess } from "../dataaccess/data.access";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { Timeout } from "../decorators/timeout.decorator";
import { S25Util } from "../util/s25-util";
import { FlsService } from "./fls.service";
import { PreferenceService } from "./preference.service";
import { UserprefService } from "./userpref.service";

//@author: Travis
export class WelcomeMessageService {
    @Timeout
    @Cache({ immutable: true, targetName: "WelcomeMessageService" })
    public static getWelcomeMessage() {
        return DataAccess.get(
            DataAccess.injectCaller("/welcome/message.json", "WelcomeMessageService.getWelcomeMessage"),
        ).then(function (obj) {
            return obj && obj.welcome_message && obj.welcome_message.message;
        });
    }

    @Timeout
    @Invalidate({ methodName: "WelcomeMessageService.getWelcomeMessage" })
    public static setWelcomeMessage(message: string) {
        return DataAccess.post(
            DataAccess.injectCaller("/welcome/message.json", "WelcomeMessageService.setWelcomeMessage"),
            { root: { message: message } },
        );
    }

    @Timeout
    public static hasWelcomeMessageWritePerms() {
        return FlsService.getFls().then(function (fls) {
            return fls && fls.SYS_MOTD === "F";
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "WelcomeMessageService" })
    public static hasAutoShowWelcomeMessage() {
        return S25Util.all({
            isLoggedIn: UserprefService.getLoggedIn(),
            prefs: PreferenceService.getGenericPreference("welcome", ["autoShow"]),
        }).then(function (data) {
            return !data.isLoggedIn || !data.prefs || S25Util.coalesce(data.prefs.autoShow, "T") === "T";
        });
    }

    @Timeout
    @Invalidate({ methodName: "WelcomeMessageService.hasAutoShowWelcomeMessage" })
    public static setAutoShowWelcomeMessage(value: any) {
        return PreferenceService.setGenericPreference("welcome", "autoShow", value);
    }
}
