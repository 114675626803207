import { Task } from "../../pojo/Task";
import { S25Util } from "../../util/s25-util";
import { Event } from "../../pojo/Event";
import { MaybeArray } from "../../pojo/Util";
import wsBlocked = Task.wsBlocked;
import Contact = Task.Contact;
import TodoWs = Task.TodoWs;
import WorkflowTypes = Task.WorkflowTypes;
import Ids = Task.Ids;
import MicroTaskWs = Task.MicroTaskWs;
import TaskListTaskItem = Task.TaskListTaskItem;
import { TaskChange } from "./task.service";

export class TaskNormalizeUtil {
    public static todoToObj(data: TodoWs): Task.Object {
        return {
            taskId: data.todo_id,
            taskType: Task.WorkflowTypes.todo,
            taskName: data.name,
            taskSubType: data.todo_subtype,
            eventId: data.object_id,
            eventName: data.object_name,
            overallState: data.cur_state_id,
            comment: data.comment,
            dueDate: new Date(data.due_date), //already a date from previous format
            contacts: [
                {
                    contId: data.assigned_to_id,
                    contName: data.assigned_to_name,
                    read: S25Util.toBool(data.read),
                    state: data.cur_state_id,
                    notifyType: Task.Ids.Todo,
                    assignedById: data.assigned_by_id,
                    assignedByName: data.assigned_by_name,
                },
            ],
        };
    }

    /*
  Tasks come from WS in 3 formats, to-do service, event service, and task service this should detect the format and normalize for easier component reusability
   */
    public static normalizeTaskData<
        T extends Parameters<
            | typeof TaskNormalizeUtil.eventTaskToObj
            | typeof TaskNormalizeUtil.todoToObj
            | typeof TaskNormalizeUtil.taskToObj
        >[0],
    >(data: T): Task.Object {
        let retTask: Task.Object;
        if ("approval_id" in data) {
            //probably event service
            retTask = TaskNormalizeUtil.eventTaskToObj(data);
        } else if ("todo_id" in data) {
            //probably to-do or task service
            retTask = TaskNormalizeUtil.todoToObj(data);
        } else if ("task_id" in data) {
            //probably task service
            retTask = TaskNormalizeUtil.taskToObj(data);
        } else {
            //couldn't detect type return original
            retTask = data;
        }

        //If the task is only FYI flag it at the parent level
        //mixed approval/NP will not be marked as FYI
        if (isFyiOnly(retTask)) {
            retTask.taskTypeId = Ids.FYI;
        }

        return retTask;
    }

    /*
  Converts a task from the tasks service to a standard format
   */
    public static taskToObj(data: Task.wsTask): Task.Object {
        return {
            taskId: parseInt(data.task_id),
            taskName: data.task_name,
            taskType: parseInt(data.task_type),
            overallState: data.task_state,
            taskSubType: data.task_subtype,
            comment: data.comments || "",
            contacts: [
                {
                    contId: S25Util.parseInt(data.assigned_to_id),
                    contName: data.assigned_to,
                    read: S25Util.toBool(data.read),
                    state: S25Util.parseInt(data.task_state),
                    notifyType: S25Util.parseInt(data.task_type),
                    assignedById: data.assigned_by_id,
                    assignedByName: data.assigned_by_name,
                },
            ],
            dueDate: new Date(data.respond_by),
            eventId: S25Util.parseInt(data.event_id),
            eventName: data.event_name,
            object: {
                itemId: S25Util.parseInt(data.object_id),
                itemName: data.object_name,
                itemTypeId: data.object_type,
            },
            isBlocked: data.task_blocked === "T",
            blockingTasks: data.blocking_task.map(normalizeBlockedTask),
        };
    }

    /*
  We often display tasks as notification policy, assignment policy or a to-do. Use to convert the given type to the less specific type
   */
    public static taskTypeToWorkflowType(taskType: number): Task.WorkflowType {
        switch (taskType) {
            case Task.Ids.Assign || Task.Ids.UnAssign:
                return Task.WorkflowTypes.assignPolicy;
            case Task.Ids.FYI || Task.Ids.Authorization:
                return Task.WorkflowTypes.notifyPolicy;
            case Task.Ids.vCalTodo || Task.Ids.Todo:
                return Task.WorkflowTypes.todo;
        }
    }

    /*
  Converts a task from the event service - approvals property to a standard format
   */
    public static eventTaskToObj(data: Event.Workflow.Task): Task.Object {
        data.approval_contact = S25Util.array.forceArray(data.approval_contact);
        data.blocking_task = S25Util.array.forceArray(data.blocking_task);
        return {
            taskId: data.approval_id,
            taskType: data.approval_type_id, // 1 or 2. Notify policy or AP see Contact.NotifyType for FYI/Approve/unassign type
            taskName: data.approval_name, //matches object.itemName
            allApprovalRequired: 2 === data.notify_type_id,
            overallState: data.approval_state,
            comment: data.approval_comments,
            dueDate: new Date(data.respond_by),
            eventId: data.eventId,
            eventName: data.eventName,
            profileId: data.approval_profile_id,
            object: {
                itemId: data.object_id,
                itemName: data.approval_name, //matches taskName
                itemTypeId: data.object_type,
                quantity: data.request_quantity,
            },
            contacts: data.approval_contact.map((cont) => {
                return {
                    contId: cont.approval_contact_id,
                    contName: cont.approval_contact_name,
                    read: S25Util.toBool(cont.read_state),
                    state: cont.approval_contact_state,
                    notifyType: cont.notification_type_id, //FYI vs Authorization vs
                };
            }),
            isBlocked: data.task_blocked === "T",
            blockingTasks: data.blocking_task.map(normalizeBlockedTask),
        };
    }

    public static eventTaskContactToObj(data: MaybeArray<Event.Workflow.Contact>): Task.Contact[] {
        return S25Util.array.forceArray(data).map((cont) => {
            return {
                contId: cont.approval_contact_id,
                contName: cont.approval_contact_name,
                read: S25Util.toBool(cont.read_state),
                state: cont.approval_contact_state,
                notifyType: cont.notification_type_id, //FYI vs Authorization vs
            };
        });
    }

    /**
     * converts the response of task micro services to a task object
     * @param task
     */
    public static microResponseToTask(task: MicroTaskWs): Task.Object {
        return {
            comment: "",
            taskId: S25Util.parseInt(task.id),
            taskType: S25Util.parseInt(task.taskType),
            kind: task.kind,
            allApprovalRequired: task.approvalFrom?.toLowerCase() === "all",
            eventId: S25Util.parseInt(task.eventId),
            profileId: S25Util.parseInt(task.profileId),
            reservationId: S25Util.parseInt(task.reservationId),
            object: {
                itemId: S25Util.parseInt(task.objectId),
                itemTypeId: S25Util.parseInt(task.objectType),
                // itemName: not included in the reponse
            },
            overallState: S25Util.parseInt(task.taskState),
            // comment:
            taskName: "", // usually object name but not currently in WS response
            dueDate: new Date(task.rsvpDt),
            updated: new Date(task.updated),
            contacts: extractContacts(task),
        };
    }

    //{"content":{"requestId":1964380,"updated":"2024-05-09T19:20:12-06:00","data":{"items":[{"kind":"task","id":545242,"etag":"00000022","updated":"2024-05-09T19:20:12-06:00","taskName":"event todo","taskCount":1,"readState":true,"taskState":2,"taskType":2,"taskTypeName":"Todo","rsvpDt":"2024-04-05T15:53:00-06:00","eventId":231184,"objectId":231184,"objectType":1,"creationDate":"2024-04-05T16:54:00.647-06:00","assignedToId":421,"assignedById":421,"active":true}]}}}

    public static taskListToTaskChange(listItem: TaskListTaskItem): TaskChange {
        return {
            eventId: listItem.eventId,
            itemId: listItem.taskId, //TaskId
            itemTypeId: listItem.itemTypeId, //Task.Id; //task type
            objectType: listItem.objectType,
            objectId: listItem.itemId,
            isTodo: listItem.isTodo,
            todoType: listItem.todoType,
            todoSubType: listItem.todoSubType,
            itemStateId: listItem.state,
            itemCount: listItem.itemCount, //Indicates how many other tasks are on the event and object
        };
    }
}

function normalizeBlockedTask(blockingTask: wsBlocked) {
    return {
        blockPriority: blockingTask.block_priority,
        blockPosition: blockingTask.block_position,
        blockingTaskType: blockingTask.blocking_task_type,
        blockingTaskId: blockingTask.blocking_task_id,
    };
}

/**
 * A single task can have both FYI and approval depending on contact.
 * This looks through the contacts for any non FYI task actions
 * @param task
 */
function isFyiOnly(task: Task.Object): boolean {
    if (task?.taskType === WorkflowTypes.notifyPolicy) {
        //Find all in progress Authorizations
        const authTasks = S25Util.array
            .forceArray(task.contacts)
            .filter((c) => c.notifyType === Task.Ids.Authorization);
        return authTasks.length === 0;
    }

    return false;
    // taskTypeId: data.approval_type_id === WorkflowTypes.assignPolicy ? Task.Ids.Assign : 1,
}

//extracts contracts from microservice response
function extractContacts(task: MicroTaskWs): Contact[] {
    if (task.approvalContacts) {
        return task.approvalContacts.map((cont) => {
            return {
                notifyType: S25Util.parseInt(cont.notifyType),
                contId: S25Util.parseInt(cont.contactId),
                read: S25Util.toBool(cont.readState),
                state: S25Util.parseInt(cont.currentNotifyState),
            } as Contact;
        });
    } else if (task.assignedToId) {
        //for todo tasks
        return [
            {
                contId: S25Util.parseInt(task.assignedToId),
                read: task.readState,
                state: S25Util.parseInt(task.taskState),
                notifyType: S25Util.parseInt(task.taskType),
            } as Contact,
        ];
    }
    console.error("no contacts on the task", task);
    return [];
}
