//@author travis
import { Timeout } from "../decorators/timeout.decorator";
import { jSith } from "../util/jquery-replacement";
import { PricingService } from "./pricing.service";
import { Cache, Invalidate } from "./../decorators/cache.decorator";
import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";

export class TaxesService {
    @Timeout
    @Cache({ immutable: true, targetName: "TaxesService" })
    public static getTaxes() {
        return PricingService.getRatesAndTaxes().then(function (data) {
            return data && data.root && data.root.taxes && data.root.taxes.tax;
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "TaxesService" })
    public static getTaxesMap() {
        return TaxesService.getTaxes().then(function (taxes) {
            var taxesMap: any = [];
            if (taxes) {
                jSith.forEach(taxes, function (_, obj) {
                    taxesMap[obj.tax_id] = obj.tax_name;
                });
            }
            return taxesMap;
        });
    }

    @Timeout
    public static getTax(taxId: number) {
        return DataAccess.get("/tax.json?tax_id=" + taxId).then((data) => {
            return S25Util.prettifyJson(data && data.taxes && data.taxes.tax && data.taxes.tax[0]);
        });
    }

    @Timeout
    @Invalidate({ serviceName: "TaxesService", methodName: "getTaxes" })
    @Invalidate({ serviceName: "TaxesService", methodName: "getTaxesMap" })
    @Invalidate({ serviceName: "PricingService", methodName: "getRatesAndTaxes" })
    public static saveTax(taxId: number, payload: any) {
        payload.tax_id = payload.tax_id || "";
        payload = { taxes: { tax: payload } };

        if (taxId) {
            payload.taxes.tax.status = "mod";
            return DataAccess.put("/tax.json?tax_id=" + taxId, payload).then((resp) => {
                return resp;
            });
        } else {
            payload.taxes.tax.status = "new";
            return DataAccess.post("/taxes.json", payload).then((resp) => {
                return resp;
            });
        }
    }

    @Timeout
    @Invalidate({ serviceName: "TaxesService", methodName: "getTaxes" })
    @Invalidate({ serviceName: "TaxesService", methodName: "getTaxesMap" })
    @Invalidate({ serviceName: "PricingService", methodName: "getRatesAndTaxes" })
    public static delete(taxId: number | string) {
        return DataAccess.delete("/tax.json?tax_id=" + taxId).then((resp) => {
            return resp;
        });
    }
}
