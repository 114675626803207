import { jSith } from "../../util/jquery-replacement";
import { S25Util } from "../../util/s25-util";

export class TodoService {
    public static findChangedTodos(orig: any[], updated: any) {
        orig = S25Util.array.forceArray(S25Util.deepCopy(orig));
        updated = S25Util.array.forceArray(S25Util.deepCopy(updated));
        let changedTodosIds: any = { new: [], changed: [], removed: [], unchanged: [] };

        jSith.forEach(orig, (_: any, obj) => {
            let loc = S25Util.array.findByProp(updated, "todo_id", obj.todo_id);
            if (loc > -1) {
                let diff = TodoService.diffObjs(obj, updated[loc]);
                diff.length > 0 && changedTodosIds.changed.push(obj.todo_id);
                diff.length === 0 && changedTodosIds.unchanged.push(obj.todo_id);
                S25Util.array.remove(updated, loc);
            } else {
                changedTodosIds.removed.push(obj.todo_id);
            }
        });

        jSith.forEach(updated, (_: any, obj) => {
            changedTodosIds.new.push(obj.todo_id);
        });

        return changedTodosIds;
    }

    public static diffObjs(a: any, b: any) {
        let props: string[] = [];
        jSith.forEach(a, (prop, value) => {
            if (a[prop] != b[prop]) {
                props.push(prop);
            }
        });
        return props;
    }
}
