import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";

export class VcalGenerateService {
    /**
     *
     * @param eventIds
     * @param exportIncompleteTodos When true, all events are exported, regardless of whether they have incomplete todos
     * @param needsSpaceCompleteAll When false, only events with locations will be exported
     * @param headcountCompleteAll When false, only export if headcount is within location's capacity
     */
    public static async generateVcal(
        eventIds: number[],
        exportIncompleteTodos: boolean,
        needsSpaceCompleteAll: boolean,
        headcountCompleteAll: boolean,
    ) {
        //vCal.generateMultiple accepts async=T. In testing we found that it is not needed in practice.

        ``;
        const url = `/vcal.GenerateMultiple?ignore_todos=${exportIncompleteTodos ? "T" : "F"}&complete_space=${needsSpaceCompleteAll ? "1" : "2"}&complete_headcount=${headcountCompleteAll ? "1" : "2"}`;
        let payload = {
            vcal_events_list: {
                vcal_events: {
                    vcal_event_list: eventIds.join("/;"),
                },
            },
        };
        let [resp, error] = await S25Util.Maybe(DataAccess.post<{ results: VcalExportResults }>(url, payload));
        if (error) {
            return;
        }

        if (resp?.results?.msg) {
            const msg = S25Util.xml.xml_str2json(`<resp>${S25Util.unescapeXml(resp.results.msg)}</resp>`)?.resp;
            resp.results.summary = msg.summary;
            resp.results.details = msg.details;
        }

        return resp?.results;
    }
}

export type VcalExportResults = {
    error: number;
    msg: string;
    summary?: string;
    details?: string;
};
