import { DataAccess } from "../dataaccess/data.access";

declare global {
    interface Window {
        unitTesting: boolean;
    }
}

export interface TelemetryEvent {
    productName: "25Live";
    eventCategory: string;
    subCategory?: string;
    eventType: string;
    eventCount?: number; // if excluded, service will set to 1 for you
}

export class TelemetryService {
    public static send(category: string, type: string) {
        return TelemetryService.sendWithSub(category, undefined, type);
    }

    public static sendWithSub(category: string, subCategory: string, type: string) {
        return TelemetryService.sendEvent({
            eventCategory: category,
            subCategory: subCategory,
            eventType: type,
            productName: "25Live",
        });
    }

    public static sendEvent(telemetryEvent: TelemetryEvent) {
        if (!window.unitTesting) {
            return DataAccess.post("/telemetry/event.json", telemetryEvent);
        }
    }
}
