import { DataAccess } from "../dataaccess/data.access";
import { Invalidate } from "../decorators/cache.decorator";
import { FlsService } from "./fls.service";

//@author
export class ViewerMessageService {
    public static getViewerMessage() {
        return DataAccess.get(
            DataAccess.injectCaller("/viewer/message.json", "ViewerMessageService.getViewerMessage"),
        ).then(function (data) {
            return data && data.viewer_message && data.viewer_message.message;
        });
    }

    @Invalidate({ methodName: "ViewerMessageService.getViewerMessage" })
    public static setViewerMessage(message: string) {
        return DataAccess.post(
            DataAccess.injectCaller("/viewer/message.json", "ViewerMessageService.setViewerMessage"),
            { root: { message: message } },
        );
    }

    public static hasViewerMessageWritePerms() {
        return FlsService.getFls().then(function (fls) {
            return fls && fls.SYS_MOTD === "F";
        });
    }
}
